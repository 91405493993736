<template>
  <v-card flat class="mt-4">
    <v-card-title>
      <v-icon left>mdi-package-variant-plus</v-icon>
      {{ $tc("global.produtoKit") }}
      <v-spacer></v-spacer>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-row dense>
        <v-col cols="12" md="4">
          <div class="pt-4 font-weight-bold text-subtitle-1">
            {{ $tc("global.selecioneProdutosKits") }}
          </div>
          <v-text-field
            :placeholder="$tc('global.buscarProdutosPorDescricao')"
            class="py-4"
            v-model="search"
            dense
            outlined
            ref="search"
            clearable
            hide-details
            autofocus
          >
          </v-text-field>
          <v-card outlined>
            <v-data-table
              v-model="produtoSelected"
              :loading="loading"
              :headers="headers"
              :items="produtos"
              :items-per-page="15"
              :search="search"
              show-select
              class="data-tables"
              fixed-header
              height="calc(100vh - 375px)"
            ></v-data-table>
          </v-card>
        </v-col>

        <v-col cols="12" md="1" class="d-flex align-center justify-center">
          <v-divider vertical></v-divider>
        </v-col>

        <v-col cols="12" md="7">
          <div class="pt-4 mb-4 font-weight-bold text-subtitle-1">
            {{ $tc("global.produto", 2) }}
            Kit
            {{ $tc("global.selecionado", 2) }}
          </div>
          <v-card outlined>
            <v-data-table
              :headers="headers_kit"
              :items="produtoSelected"
              hide-default-footer
              :items-per-page="-1"
              class="data-tables"
              fixed-header
              height="calc(100vh - 258px)"
            >
              <template v-slot:item.qtde="{ item, index }">
                <v-text-field
                  :key="i"
                  v-model.number="item.qtde"
                  :placeholder="$tc('global.qtd')"
                  outlined
                  dense
                  hide-details
                  class="pa-2"
                  append-outer-icon="mdi-plus"
                  @click:append-outer="plusItemKit(item, index)"
                  prepend-icon="mdi-minus"
                  @click:prepend="minusItemKit(item, index)"
                ></v-text-field>
              </template>
              <template v-slot:item.preco="{ item }">
                <v-text-field
                  :key="i"
                  v-model="item.preco"
                  :placeholder="$tc('global.preco')"
                  outlined
                  dense
                  :error="item.preco <= 0"
                  hide-details
                  class="pa-2"
                  v-currency="vCurrencyOptions"
                ></v-text-field>
              </template>
              <template v-slot:item.actions="{ index }">
                <v-btn icon small @click="deleteKitItem(index)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { getAllProdutosNotKit } from "@/api/produtos/produtos.js";
export default {
  name: "ProdutosKit",

  props: {
    produtoSelected: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      loading: false,
      search: "",
      i: 0,
      produtos: [],
    };
  },

  computed: {
    vCurrencyOptions() {
      return {
        precision: 0,
        distractionFree: false,
      };
    },
    headers() {
      return [
        {
          text: "id",
          value: "id",
          align: "start",
          width: "70px",
          divider: true,
        },
        {
          text: "Ref.",
          value: "referencia",
          align: "center",
          width: "80px",
          divider: true,
        },
        {
          text: this.$tc("global.descricao"),
          value: "descricao",
        },
      ];
    },
    headers_kit() {
      return [
        {
          text: "id",
          value: "id",
          align: "start",
          width: "70px",
          divider: true,
        },
        {
          text: "Ref.",
          value: "referencia",
          align: "center",
          width: "80px",
          divider: true,
        },
        {
          text: this.$tc("global.descricao"),
          value: "descricao",
        },
        {
          text: this.$tc("global.qtd"),
          value: "qtde",
        },
        {
          text: this.$tc("global.preco"),
          value: "preco",
        },

        {
          text: "",
          value: "actions",
          align: "center",
        },
      ];
    },
  },

  watch: {
    produtoSelected() {
      this.produtoSelected.map((produto) => {
        if (!produto.qtde) {
          produto.qtde = 1;
        }
      });

      this.$emit("update:produtoSelected", this.produtoSelected);
    },
  },

  methods: {
    getProdutos() {
      this.loading = true;
      return getAllProdutosNotKit("?produto_kit=true")
        .then((response) => {
          this.produtos = response;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    deleteKitItem(index) {
      this.produtoSelected.splice(index, 1);
    },

    plusItemKit(item, index) {
      this.produtoSelected[index].qtde = Number(item.qtde) + 1;
      this.i++;
    },

    minusItemKit(item, index) {
      if (Number(item.qtde) > 1) {
        this.produtoSelected[index].qtde = Number(item.qtde) - 1;
        this.i++;
      }
    },
  },

  async mounted() {
    await this.getProdutos();
  },
};
</script>

<style></style>
